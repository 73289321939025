import { Translation } from 'infrastructure/i18n/typing';
import { EndOfProductionDateTranslations } from './typing';

export const EndOfProductionDateText: Translation<EndOfProductionDateTranslations> =
  {
    es: {
      pageTitle: 'Cese',
      menuTitle: 'Cese',
      select: 'Seleccionar',
      emptyMessage: 'Aún no hay stock de cese disponible para este proveedor.',
      code: 'Clave',
      description: 'Descripción',
      expirationDate: 'Fecha de cese',
      model: 'Modelo',
      endOfConsumptionDate: 'Fecha fin de consumo',
      cost: '€ Pieza',
      consumption: 'Consumos',
      deliveryPlan: 'Plan entrega',
      providerNames: 'Proveedor',
      percentageAsignements: '% Proveedor',
      providerInbounders: 'Inbounder',
      stockSeat: 'Stock en SEAT',
      excessPotencial: 'Potencial sobrante',
      situationStatus: 'Status de situación',
      warehouses: 'Almacén',
      stuckliste_models: 'Modelo en stuckliste',
      termins: 'Terminschlüssel',
      leftoverStock: 'Stock sobrante',
      leftoverStockCost: '€ Stock Sobrante',
      inbounderMultiselectAvailable: 'Inbounders disponibles',
      inbounderMultiselectSelected: 'Inbounders seleccionados',
      inbounderMultiselectEmpty: 'No hay inbounders disponibles',
      modelMultiselectAvailable: 'Modelos disponibles',
      modelMultiselectSelected: 'Modelos seleccionados',
      modelMultiselectEmpty: 'No hay modelos disponibles',
      situationStatusMultiselectAvailable: 'Status disponibles',
      situationStatusMultiselectSelected: 'Status seleccionados',
      situationStatusMultiselectEmpty: 'No hay status disponibles',
      warehouseMultiselectAvailable: 'Almacenes disponibles',
      warehouseMultiselectSelected: 'Almacenes seleccionados',
      warehouseMultiselectEmpty: 'No hay almaceneses disponibles',
      codes: 'Número de claves',
      valueInEuros: 'Valor en euros',
      ceasedKeys: 'Claves cesadas',
      ceasedKeysTooltip:
        'Claves con la <b>fecha de cese cumplida</b> o con <b>status de situación = 3</b>',
      obsoleteKeys: 'Claves potencial obsoleto',
      obsoleteKeysHorizon75days:
        'Claves potencial obsoleto (horizonte 75 días)',
      materialInTransit: 'Mat. en tránsito',
      blockedMaterial: 'Stock bloqueado',
      sleeperKeys:
        'Claves durmientes (200 días sin consumo, necesidades a 75 días)',
      sleeperKeysBox: 'Claves durmientes horizonte necesidades 75 días',
      sleeperKeysTooltip:
        'Claves que hace <b>más de 200 días que no tienen consumos ni salidas</b> a línea',
      sleeperKeysWithNeeds: 'Claves durmientes con necesidad',
      sleeperKeysWithNeedsTooltip:
        'Claves con <b>200 días sin consumo o salidas a línea y CON necesidades</b> en los siguientes 75 días',
      sleeperKeysWithoutNeeds: 'Claves durmientes sin necesidad',
      sleeperKeysWithoutNeedsTooltip:
        'Claves con <b>200 días sin consumo o salidas a línea y SIN necesidades</b> en los siguientes 75 días',
      overview: 'Visión general',
      statusSituationInfoTooltip:
        '<b>0:</b> en proceso de entrada en vigor <br/><b>1:</b> en vigor <br/><b>2:</b> en proceso de cese <br/><b>3:</b> obsoleta <br/><b>4:</b> recambios',
      obsoletePotentialKeysTooltip:
        'Claves definidas que son:<ul><li><b>Cesadas</b></li><li>Con <b>fecha de cese en los próximos 75 días</b> y que <b>tienen consumos previstos</b> (en los siguientes 75 días)</li></ul>',
      stockVolume: 'Volumen stock',
      consumptionTooltip: 'El horizonte de consumos contempla 75 días',
      endOfConsumptionDateTooltip:
        '<b>Última fecha con consumos</b> planificados. <ul><li>MAX = Fecha sin consumo > 75 días</li></ul>',
      terminsTooltip:
        'Terminschlüssel: Intervención utilizada para documentar o modificar el producto',
      leftoverStockTooltip:
        'El <b>stock sobrante</b> se define como:<ul><li><b>Stock SEAT</b> (NOJIT + Direct Delivery)<b> + Plan de entrega</b> (los próximos 75 días)<b> + Material en tránsito - Consumos</b> (los próximos 75 días)</li></ul>',
      leftoverStockCostTooltip: 'Stock sobrante * €/pieza',
      blockedMaterialTooltip:
        '<b>Cantidad</b> de piezas <b>bloqueadas por calidad</b> y que se deben retrabajar.<br/><br/><b>No incluidas en stock sobrante</b>.',
      percentageAsignementsTooltip:
        'Porcentaje de asignación a proveedor. <ul><li>Si la clave de material solo es <b>fabricada por un proveedor</b> se aplica <b>100%</b></li><li>Si la clave de material es <b>fabricada por más de un proveedor</b> se visualiza <b>balance</b> asignado a cada proveedor <b>separado por |</b></li></ul>',
      sleeperKeysWithoutNeedsInTransitTooltip:
        'Clave durmiente:<ul><li>Sin necesidades</li><li>Con material en tránsito activo</li></ul>',
      sleeperKeysWithoutNeedsAmountOrderedTooltip:
        'Clave durmiente:<ul><li>Sin necesidades</li><li>Con plan de entrega activo</li></ul>',
      stockTotalVolume: 'Valor en metros cúbicos',
      stockCoverageUpdateDate: 'Actualización stock: ',
      needsUpdateDate: 'Actualización plan producción montaje: ',
      stuecklisteVWUpdateDate: 'Actualización stückliste: ',
      sapMrwUpdateDate: 'Actualización plan entrega: ',
      lastEntry: 'Última entrada',
      withInconsistencies: 'Con incoherencias',
      codesWithInconsistencies: 'Claves con incoherencias',
      codesWithInconsistenciesTooltip:
        'Claves con <b>fechas de inicio y fin inconsistentes</b>, es necesario revisarlas',
      keyType: 'Tipo clave'
    },
    en: {
      pageTitle: 'End of Production Date',
      menuTitle: 'End of Production Date',
      select: 'Select',
      emptyMessage:
        'There is no available end of production date stock yet for this provider.',
      code: 'Part',
      description: 'Description',
      expirationDate: 'Expiration date',
      model: 'Model',
      endOfConsumptionDate: 'End of consumption date',
      cost: '€ Piece',
      consumption: 'Consumptions',
      deliveryPlan: 'Delivery Plan',
      providerNames: 'Suppliers',
      percentageAsignements: '% Suppliers',
      providerInbounders: 'Inbounder',
      stockSeat: 'SEAT stock',
      excessPotencial: 'Excess Potencial',
      situationStatus: 'Status of situation',
      warehouses: 'Warehouse',
      stuckliste_models: 'Model in stuckliste',
      termins: 'Terminschlüssel',
      leftoverStock: 'Leftover stock',
      leftoverStockCost: '€ Stock Leftover',
      inbounderMultiselectAvailable: 'Inbounders available',
      inbounderMultiselectSelected: 'Inbounders selected',
      inbounderMultiselectEmpty: "There aren't inbounders available",
      modelMultiselectAvailable: 'Models available',
      modelMultiselectSelected: 'Models selected',
      modelMultiselectEmpty: "There aren't models available",
      situationStatusMultiselectAvailable: 'Status available',
      situationStatusMultiselectSelected: 'Status selected',
      situationStatusMultiselectEmpty: "There aren't status available",
      warehouseMultiselectAvailable: 'Warehouse available',
      warehouseMultiselectSelected: 'Warehouse selected',
      warehouseMultiselectEmpty: "There aren't warehouse available",
      codes: 'Number of keys',
      valueInEuros: 'Value in euros',
      ceasedKeys: 'Ceased keys',
      ceasedKeysTooltip:
        'Keys with the <b>cessation date fulfilled</b> or with <b>situation status = 3</b>',
      obsoleteKeys: 'Potentially obsolete keys',
      obsoleteKeysHorizon75days: 'Potentially obsolete keys (75 day horizon)',
      materialInTransit: 'Mat. in transit',
      blockedMaterial: 'Blocked stock',
      sleeperKeys:
        'Sleeper keys (200 days without consumption, needs within 75 days)',
      sleeperKeysBox: 'Sleeper keys horizon needs 75 days',
      sleeperKeysTooltip:
        'Parts that <b>have not had consumption or online output for more than 200 days</b>',
      sleeperKeysWithNeeds: 'Sleeper keys with needs',
      sleeperKeysWithNeedsTooltip:
        'Keys with <b>200 days without consumption or online outputs and WITH needs</b> in the next 75 days',
      sleeperKeysWithoutNeeds: 'Sleeper keys without needs',
      sleeperKeysWithoutNeedsTooltip:
        'Keys with <b>200 days without consumption or online outputs and WITHOUT needs</b> in the next 75 days',
      overview: 'Overview',
      statusSituationInfoTooltip:
        '<b>0:</b> in the process of entry in effect <br/><b>1:</b> in effect <br/><b>2:</b> in process of end of production <br/><b>3:</b> obsolete <br/><b>4:</b> spare parts',
      obsoletePotentialKeysTooltip:
        'Defined keys that are:<ul><li><b>Ceased</b></li><li>With a <b>cessation date in the next 75 days</b> and that <b>have planned consumption</b> (in the next 75 days)</li></ul>',
      stockVolume: 'Stock volume',
      consumptionTooltip: 'The consumption horizon contemplates 75 days',
      endOfConsumptionDateTooltip:
        '<b>Last date with planned consumption</b>. <ul><li>MAX = Date without consumption > 75 days</li></ul>',
      terminsTooltip:
        'Terminschlüssel: Intervention used to document or modify the product',
      leftoverStockTooltip:
        'The <b>surplus stock</b> is defined as:<ul><li><b>SEAT stock</b> (NOJIT + Direct Delivery)<b> + Delivery plan</b> (the next 75 days) <b>+ Material in transit - Consumption</b> (the next 75 days)</li></ul>',
      leftoverStockCostTooltip: 'Leftover stock * €/piece',
      blockedMaterialTooltip:
        '<b>Quantity</b> of parts <b>blocked by quality</b> and that must be reworked.<br/><br/><b>Not included in surplus stock</b>.',
      percentageAsignementsTooltip:
        'Percentage of allocation to supplier. <ul><li>If the material key is only <b>manufactured by one supplier 100% applies</b></li><li>If the material key is <b>manufactured by more than one supplier balance</b> is displayed assigned to each supplier <b>separately by |</b></li></ul>',
      sleeperKeysWithoutNeedsInTransitTooltip:
        'Sleeping key: <ul><li>No needs</li><li>With material in active transit</li></ul>',
      sleeperKeysWithoutNeedsAmountOrderedTooltip:
        'Sleeping key:<ul><li>No needs</li><li>With active delivery plan</li></ul>',
      stockTotalVolume: 'Value in cubic meters',
      stockCoverageUpdateDate: 'Stock update: ',
      needsUpdateDate: 'Production assembly plan update: ',
      stuecklisteVWUpdateDate: 'Stücklist update: ',
      sapMrwUpdateDate: 'Delivery plan update: ',
      lastEntry: 'Last entry',
      withInconsistencies: 'With inconsistencies',
      codesWithInconsistencies: 'Parts with inconsistencies',
      codesWithInconsistenciesTooltip:
        'Keys with <b>inconsistent start and end dates</b>, need to be reviewed',
      keyType: 'Key type'
    },
    ca: {
      pageTitle: 'Cessament',
      menuTitle: 'Cessament',
      select: 'Seleccionar',
      emptyMessage:
        'Encara no hi ha existències de cessament disponibles per a aquest proveïdor.',
      code: 'Clau',
      description: 'Descripció',
      expirationDate: 'Data de cessament',
      model: 'Model',
      endOfConsumptionDate: 'Data de fí de consum',
      cost: '€ Peça',
      consumption: 'Consums',
      deliveryPlan: 'Pla lliurament',
      providerNames: 'Proveïdor',
      percentageAsignements: '% Proveïdor',
      providerInbounders: 'Inbounder',
      stockSeat: 'Stock a SEAT',
      excessPotencial: 'Excés Potencial',
      situationStatus: 'Status de situació',
      warehouses: 'Magatzem',
      stuckliste_models: 'Model en stuckliste',
      termins: 'Terminschlüssel',
      leftoverStock: 'Estoc sobrant',
      leftoverStockCost: '€ Estoc Sobrant',
      inbounderMultiselectAvailable: 'Inbounders disponibles',
      inbounderMultiselectSelected: 'Inbounders seleccionats',
      inbounderMultiselectEmpty: 'No hi ha inbounders disponibles',
      modelMultiselectAvailable: 'Models disponibles',
      modelMultiselectSelected: 'Models seleccionats',
      modelMultiselectEmpty: 'No hi ha models disponibles',
      situationStatusMultiselectAvailable: 'Status disponibles',
      situationStatusMultiselectSelected: 'Status seleccionats',
      situationStatusMultiselectEmpty: 'No hi ha status disponibles',
      warehouseMultiselectAvailable: 'Magatzems disponibles',
      warehouseMultiselectSelected: 'Magatzems seleccionados',
      warehouseMultiselectEmpty: 'No hi ha Magatzems disponibles',
      codes: 'Nombre de claus',
      valueInEuros: 'Valor en euros',
      ceasedKeys: 'Claus cessades',
      ceasedKeysTooltip:
        'Claus amb la <b>data de cessament complerta</b> o amb <b>status de situació = 3</b>',
      obsoleteKeys: 'Claus potencial obsolet',
      obsoleteKeysHorizon75days: 'Claus potencial obsolet (horitzó 75 dies)',
      materialInTransit: 'Mat. en trànsit',
      blockedMaterial: 'Stock bloquejat',
      sleeperKeys:
        'Claus dorments (200 dies sense consum, necessitats a 75 dies)',
      sleeperKeysBox: 'Claus dorments horitzó necessitats 75 dies',
      sleeperKeysTooltip:
        'Claus que fa <b>més de 200 dies que no tenen consums ni sortides</b> a línia',
      sleeperKeysWithNeeds: 'Claus dorments amb necessitats',
      sleeperKeysWithNeedsTooltip:
        'Claus amb <b>200 dies sense consum o sortides a línia i AMB necessitats</b> en els següents 75 dies',
      sleeperKeysWithoutNeeds: 'Claus dorments sense necessitats',
      sleeperKeysWithoutNeedsTooltip:
        'Claus amb <b>200 dies sense consum o sortides a línia i SENSE necessitats</b> en els següents 75 dies',
      overview: 'Visió general',
      statusSituationInfoTooltip:
        "<b>0:</b> en procés d'entrada en vigor <br/><b>1:</b> en vigor <br/><b>2:</b> en procés de cessament <br/><b>3:</b> obsoleta <br/><b>4:</b> recanvis",
      obsoletePotentialKeysTooltip:
        'Claus definides que són:<ul><li><b>Cessades</b></li><li>Amb <b>data de cessament en els propers 75 dies</b> i que <b>tenen consums previstos </b> (en els 75 dies següents)</li></ul>',
      stockVolume: 'Volum estoc',
      consumptionTooltip: "L'horitzó de consums contempla 75 dies",
      endOfConsumptionDateTooltip:
        '<b>Última data amb consums</b> planificats. <ul><li>MAX = Data sense consum > 75 dies</li></ul>',
      terminsTooltip:
        'Terminschlüssel: Intervenció utilitzada per documentar o modificar el producte',
      leftoverStockTooltip:
        "L'<b>stock sobrant</b> es defineix com:<ul><li><b>Stock SEAT</b> (NOJIT + Direct Delivery)<b> + Pla de lliurament </b> (els propers 75 dies) <b>+ Material en trànsit - Consums</b> (els propers 75 dies)</li></ul>",
      leftoverStockCostTooltip: 'Estoc sobrant * €/peça',
      blockedMaterialTooltip:
        "<b>Quantitat</b> de peces <b>bloquejades per qualitat</b> i que s'han de retreballar.<br/><br/><b>No incloses en estoc sobrant</b>.",
      percentageAsignementsTooltip:
        "Percentatge d‟assignació a proveïdor. <ul><li>Si la clau de material només és <b>fabricada per un proveïdor</b> s'aplica <b>100%</b></li><li>Si la clau de material és <b> >fabricada per més d'un proveïdor</b> es visualitza <b>balanç</b> assignat a cada proveïdor <b>separat per |</b></li></ul>",
      sleeperKeysWithoutNeedsInTransitTooltip:
        'Clau dorment:<ul><li>Sense necessitats</li><li>Amb material en trànsit actiu</li></ul>',
      sleeperKeysWithoutNeedsAmountOrderedTooltip:
        'Clau dorment:<ul><li>Sense necessitats</li><li>Amb pla de lliurament actiu</li></ul>',
      stockTotalVolume: 'Valor en metres cúbics',
      stockCoverageUpdateDate: 'Actualització estoc: ',
      needsUpdateDate: 'Actualització pla producció muntatge: ',
      stuecklisteVWUpdateDate: 'Actualització stückliste: ',
      sapMrwUpdateDate: 'Actualització del pla de lliurament: ',
      lastEntry: 'Última entrada',
      withInconsistencies: 'Amb incoherències',
      codesWithInconsistencies: 'Claus amb incoherències',
      codesWithInconsistenciesTooltip:
        "Claus amb <b>dates d'inici i fi inconsistents</b>, cal revisar-les",
      keyType: 'Tipus clau'
    }
  };
