import { Translation } from 'infrastructure/i18n/typing';
import { ContactRolesTranslations, DirectDeliveryTranslations } from './typing';

export const DirectDeliveryText: Translation<DirectDeliveryTranslations> = {
  es: {
    pageTitle: 'Direct Delivery',
    menuTitle: 'DD',
    emptyMessage: 'Aún no hay datos de Direct Delivery disponibles',
    stockUpdatedAt: 'Act. stock: ',
    assemblyNeedsUpdatedAt: 'Act. plan producción montaje: ',
    bodyworkNeedsUpdatedAt: 'Act. plan producción chapistería: ',
    code: 'Clave',
    providerCode: 'Clave prov.',
    description: 'Descripción',
    warehouseId: 'Almacén',
    models: 'Modelos',
    providerId: 'Proveedor',
    stock: 'Stock',
    containerAtMarquee: 'Cont.',
    containerId: 'Lison',
    containerInCycle: 'Cont. ciclo',
    directDeliveryBoard: 'Tabla Direct Delivery',
    upcomingTransitsTitle: 'Próximos tránsitos',
    upcomingTransitsTooltip:
      '<b>Tránsitos activos llegando a fábrica.</b><br/><br/>No tienen confirmaciones de etiquetas de materiales recepcionadas en Martorell',
    plateNumber: 'Matrícula',
    eta: 'ETA',
    countContainerLabel: '#Etiquetas',
    morning: 'Mañana',
    afternoon: 'Tarde',
    night: 'Noche',
    lines: 'Linea',
    warehouseSourceMultiselectAvailable: 'Almacen de origen disponibles',
    warehouseSourceMultiselectSelected: 'Almacen de origen seleccionados',
    warehouseSourceMultiselectEmpty: 'no hay almacenes de origen disponibles',
    linesMultiselectAvailable: 'Lineas disponibles',
    linesMultiselectSelected: 'Lineas seleccionadas',
    linesMultiselectEmpty: 'No hay lineas disponibles',
    modelsMultiselectAvailable: 'Modelos disponibles',
    modelsMultiselectSelected: 'Modelos seleccionados',
    modelsMultiselectEmpty: 'No hay modelos disponibles',
    providerIdsMultiselectAvailable: 'No N2 disponibles',
    providerIdsMultiselectSelected: 'No N2 seleccionados',
    providerIdsMultiselectEmpty: 'No hay No N2 disponibles',
    finalStockQuantity: 'Stock',
    finalStockDatetime: ' F. Act. stock T1',
    unitSinglePiece: 'pza.',
    unitMultiplePieces: 'pzas.',
    providerT1: 'Proveedor T1',
    coverage: 'Cobertura',
    marquee: 'Marquesina',
    coverageAtRisk: 'Coberturas en riesgo',
    delivered: 'Entregado',
    averageConsumption: 'Consumo medio',
    averageCoverage: 'Cob. media',
    averageConsumptionTooltip:
      '<b>Promedio de necesidades</b> sobre el <b>turno actual</b> y los <b>próximos 2 turnos productivos</b> basados en el <b>Programa de Chapa.</b> El cálculo considera todos los modelos con material asignado en el programa.',
    averageCoverageTooltip:
      '<b>Horas cubiertas con el stock</b> calculadas con el consumo medio sobre el <b>turno actual</b> y los <b>próximos 2 turnos productivos</b> basados en el <b>Programa de Chapa</b>.<ul><li>MAX = El stock es superior al consumo medio de necesidades</li></ul>',
    status: 'Estado',
    statusTooltip:
      'Criticidad de paro de turno. <br/><br/><b>Estado NOK:</b> La cobertura de la pieza es menor a 1 turno (8horas). <br/><b>Estado OK:</b> La cobertura de la pieza es superior a 1 turno (8horas).',
    searchPlaceholder: 'Búsqueda por clave, descripción o clave proveedor',
    amount: 'Cant.',
    deliveryTime: 'Llegada',
    providerDeliveryNote: 'Albarán',
    date: 'Fecha: ',
    receptionLabels: 'Recepción etiquetas',
    TooltipReceptionLabels: 'Los datos descargados cubren los últimos 3 meses.',
    downloadHistory: 'Descargar histórico',
    contacts: 'Contactos',
    downloadTransits: 'Descargar tránsitos',
    all: 'Todos',
    exportKanbanLabelsReceived:
      'Exporta las etiquetas KANBAN recibidas en la fábrica',
    exportKanbanLabelsOnboarding:
      'Ahora, disfruta con la seguridad de saber qué etiquetas de proveedor se han recibido diariamente en la fábrica. Se pueden visualizar las etiquetas recibidas, el albarán, la matrícula del transporte entre otros. \n\n Todos los materiales entrados en la fábrica y escaneados con la PDA, se guardan en la exportación para comprobantes y reclamaciones hacia los proveedores.'
  },
  en: {
    pageTitle: 'Direct Delivery',
    menuTitle: 'DD',
    emptyMessage: 'There is no Direct Delivery data available',
    stockUpdatedAt: 'Stock upd.: ',
    assemblyNeedsUpdatedAt: 'Upd. assembly production plan: ',
    bodyworkNeedsUpdatedAt: 'Upd. bodywork production plan: ',
    code: 'Part',
    providerCode: 'Provider part',
    description: 'Description',
    warehouseId: 'Warehouse',
    models: 'Models',
    providerId: 'Provider',
    stock: 'Stock',
    containerAtMarquee: 'Cont.',
    containerId: 'Lison',
    containerInCycle: 'Cont. Cycle',
    directDeliveryBoard: 'Direct Delivery Board',
    upcomingTransitsTitle: 'Upcoming transits',
    upcomingTransitsTooltip:
      '<b>Active transits arriving at the factory.</b><br/><br/>They do not have confirmations of material labels received in Martorell',
    plateNumber: 'License plate',
    eta: 'ETA',
    countContainerLabel: '#Labels',
    morning: 'Morning',
    afternoon: 'Afternoon',
    night: 'Night',
    lines: 'Line',
    warehouseSourceMultiselectAvailable: 'Warehouse origins available',
    warehouseSourceMultiselectSelected: 'Warehouse origins selected',
    warehouseSourceMultiselectEmpty: "There aren't warehouse origins available",
    linesMultiselectAvailable: 'Lines availables',
    linesMultiselectSelected: 'Lines selected',
    linesMultiselectEmpty: "There aren't lines available",
    modelsMultiselectAvailable: 'Models available',
    modelsMultiselectSelected: 'Models selected',
    modelsMultiselectEmpty: "There aren't models available",
    providerIdsMultiselectAvailable: 'No N2 available',
    providerIdsMultiselectSelected: 'No N2 selected',
    providerIdsMultiselectEmpty: "There aren't No N2 available",
    finalStockQuantity: 'Stock',
    finalStockDatetime: 'D. Upd. stock T1',
    unitSinglePiece: 'pc',
    unitMultiplePieces: 'pcs',
    providerT1: 'Provider T1',
    coverage: 'Coverage',
    marquee: 'Marquee',
    coverageAtRisk: 'Coverage at risk',
    delivered: 'Delivered',
    averageConsumption: 'Average consumption',
    averageCoverage: 'Average coverage',
    averageConsumptionTooltip:
      '<b>Average needs</b> over the <b>current shift</b> and the <b>next 2 productive shifts</b> based on the <b>Sheet Metal Program.</b> The calculation considers all models with material assigned in the program.',
    averageCoverageTooltip:
      '<b>Hours covered with the stock</b> calculated with the average consumption about the <b>current shift</b> and the <b>next 2 productive shifts</b> based on the <b>Bodyshop Program</b>.<ul><li>MAX = The stock is greater than the average consumption needs</li></ul>',
    status: 'Status',
    statusTooltip:
      "Criticality of shift stoppage.<br/><br/><b>NOK Status:</b> The coverage of the part is less than 1 shift (8 hours).<br/><b>OK status:</b> The part's coverage is greater than 1 turn (8 hours).",
    searchPlaceholder: 'Search by part, description or provider part',
    amount: 'Amt.',
    deliveryTime: 'Arrival',
    providerDeliveryNote: 'Prov. D. Note',
    date: 'Date: ',
    receptionLabels: 'Reception labels',
    TooltipReceptionLabels: 'The downloaded data covers the last 3 months.',
    downloadHistory: 'Download history',
    contacts: 'Contacts',
    downloadTransits: 'Download transits',
    all: 'All',
    exportKanbanLabelsReceived:
      'Export the KANBAN labels received at the factory',
    exportKanbanLabelsOnboarding:
      'Now, enjoy the security of knowing which supplier labels have been received daily at the factory. You can view the labels received, the delivery note, the transport license plate, among others. \n\n All materials entered into the factory and scanned with the PDA are saved during export for receipts and claims against suppliers.'
  },
  ca: {
    pageTitle: 'Direct Delivery',
    menuTitle: 'DD',
    emptyMessage: 'No hi ha dades de Direct Delivery disponibles',
    stockUpdatedAt: 'Act. estoc: ',
    assemblyNeedsUpdatedAt: 'Act. pla producció muntatge: ',
    bodyworkNeedsUpdatedAt: 'Act. pla producció xapisteria ',
    code: 'Clau',
    providerCode: 'Clau prov.',
    description: 'Descripció',
    warehouseId: 'Magatzem',
    models: 'Models',
    providerId: 'Proveïdor',
    stock: 'Stock',
    containerAtMarquee: 'Cont',
    containerId: 'Lison',
    containerInCycle: 'Cont. cicle',
    directDeliveryBoard: 'Taula Direct Delivery',
    upcomingTransitsTitle: 'Propers trànsits',
    upcomingTransitsTooltip:
      "<b>Trànsits actius arribant a fàbrica.</b><br/><br/>No tenen confirmacions d'etiquetes de materials rebudes a Martorell",
    plateNumber: 'Matrícula',
    eta: 'ETA',
    countContainerLabel: '#Etiquetes',
    morning: 'Matí',
    afternoon: 'Tarda',
    night: 'Nit',
    lines: 'Línia',
    warehouseSourceMultiselectAvailable: "Magatzem d'origen disponibles",
    warehouseSourceMultiselectSelected: "Magatzem d'origen seleccionats",
    warehouseSourceMultiselectEmpty: "No hi ha Magatzem d'origen disponibles",
    linesMultiselectAvailable: 'Línies disponibles',
    linesMultiselectSelected: 'Línies seleccionades',
    linesMultiselectEmpty: 'No hi ha línies disponibles',
    modelsMultiselectAvailable: 'Models disponibles',
    modelsMultiselectSelected: 'Models seleccionats',
    modelsMultiselectEmpty: 'No hi ha models disponibles',
    providerIdsMultiselectAvailable: 'No N2 disponibles',
    providerIdsMultiselectSelected: 'No N2 seleccionades',
    providerIdsMultiselectEmpty: 'No hi ha No N2 disponibles',
    finalStockQuantity: 'Stock',
    finalStockDatetime: 'D. Act. Stock T1',
    unitSinglePiece: 'peça',
    unitMultiplePieces: 'peces',
    providerT1: 'Proveïdor T1',
    coverage: 'Cobertura',
    marquee: 'Marquesina',
    coverageAtRisk: 'Coberturas en risc',
    delivered: 'Lliurat',
    averageConsumption: 'Consum mitjà',
    averageCoverage: 'Cob. mitjana',
    averageConsumptionTooltip:
      '<b>Mitjana de necessitats</b> sobre el <b>torn actual</b> i els <b>propers 2 torns productius</b> basats en el <b>Programa de Xapa.</b> El càlcul considera tots els models amb material assignat al programa.',
    averageCoverageTooltip:
      "<b>Hores cobertes amb l'estoc</b> calculades amb el consum mitjà sobre el <b>torn actual</b> i els <b>propers 2 torns productius</b> basats en el <b>Programa de Xapa</b>.<ul><li>MAX = L'estoc és superior al consum mitjà de necessitats</li></ul>",
    status: 'Estat',
    statusTooltip:
      "Criticitat d'atur de torn.<br/><br/><b>Estat NOK:</b> La cobertura de la peça és menor a 1 torn (8 hores). <br/><b>Estat OK:</b> La cobertura de la peça és superior a 1 torn (8 hores).",
    searchPlaceholder: 'Cerca per clau, descripció o clau proveïdor',
    amount: 'Quant.',
    deliveryTime: 'Arribada',
    providerDeliveryNote: 'Albarà',
    date: 'Data: ',
    receptionLabels: 'Recepció etiquetes',
    TooltipReceptionLabels:
      'Les dades descarregades cobreixen els darrers 3 mesos.',
    downloadHistory: 'Descarregar històric',
    contacts: 'Contactes',
    downloadTransits: 'Descarregar trànsits',
    all: 'Tots',
    exportKanbanLabelsReceived:
      'Exporta les etiquetes KANBAN rebudes a la fàbrica',
    exportKanbanLabelsOnboarding:
      "Ara gaudeix amb la seguretat de saber quines etiquetes de proveïdor s'han rebut diàriament a la fàbrica. Podeu visualitzar les etiquetes rebudes, l'albarà, la matrícula del transport entre d'altres.\n\n Tots els materials entrats a la fàbrica i escanejats amb la PDA, es guarden a l'exportació per a comprovants i reclamacions cap als proveïdors."
  }
};

export const ContactRolesText: Translation<ContactRolesTranslations> = {
  es: {
    logisticsManager: 'Responsable logística',
    expeditions24Hours: 'Expediciones 24 horas',
    responsibleExpedition: 'Responsable de exp.',
    logisticsDirector: 'Director logístico',
    genericEmail: 'Correo genérico',
    commercialGroup: 'Comercial grupo',
    logisticsDisponent: 'Disponente logístico'
  },
  en: {
    logisticsManager: 'Logistics manager',
    expeditions24Hours: '24 hour expeditions',
    responsibleExpedition: 'Responsible for exp.',
    logisticsDirector: 'Logistics director',
    genericEmail: 'Generic email',
    commercialGroup: 'Commercial group',
    logisticsDisponent: 'Logistics disponent'
  },
  ca: {
    logisticsManager: 'Responsable logística',
    expeditions24Hours: 'Expedicions 24 hores',
    responsibleExpedition: "Responsable d'exp.",
    logisticsDirector: 'Director logístic',
    genericEmail: 'Correu genèric',
    commercialGroup: 'Comercial grup',
    logisticsDisponent: 'Disponent logístic'
  }
};
